@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --black: rgba(0, 0, 0, 1);
  --gray: rgba(143, 143, 143, 1);
  --lightgray: rgba(219, 219, 219, 1);
  --main: rgba(133, 122, 255, 1);
  --white: rgba(255, 255, 255, 1);
}

code {
  color: #3a3a3a;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  background-color: rgb(239, 239, 239);
}